import React from 'react'
import { Container } from 'react-bootstrap'

export default function NotFound() {
  return (
    <Container>
        <div className='lostWords'>Are you lost? <br />Try somethings else...</div>
    </Container>
  )
}
